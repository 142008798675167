<template>
  <v-row>
    <v-col cols="12">
      <!-- order total card -->

      <!-- Main Card -->
      <app-card-actions @refresh="fetchOrders()">
        <template slot="title"> Orders List </template>


        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="OrderListTable"
          item-key="Id"
          :items-per-page="-1"
          :options.sync="options"
          :expanded.sync="expanded"
          show-expand
          :loading="loading"
          :sort-desc="[true]"
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- CreationTime -->
          <template #[`item.CreationTime`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{
                  filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
                }}</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).title
              }}
            </v-chip>
          </template>
           <template #[`item.TotalAmount`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.TotalAmount.toFixed(3) }} JD</span>
              </div>
            </div>
          </template>
          <!-- Action -->
          <template #[`item.Action`]="{ item }">
            <v-btn v-if="item.Status == 'pinding'" color="info" small @click="PushNotification(item.BranchId)">Push</v-btn>
          </template>
          <!-- expandad data -->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="6" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Order Items</h3></span>
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8"> </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>
                <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
                  <v-col lg="7" cols="7"
                    >{{ orderTransation.Name }}
                    <br />
                    <span v-for="(Addon, i) in orderTransation.Addon2" :key="i"
                      >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                    /></span>
                    <span v-if="orderTransation.Description != ''"
                      >Note : {{ orderTransation.Description }} <br /></span>
                  </v-col>
                  <v-col lg="2" cols="2">
                    {{ orderTransation.Qty }}
                  </v-col>
                  <v-col>
                    {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(2) }}
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-1"></v-divider>

                <v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0).toFixed(2)
                      }}
                      JOD
                    </span>
                  </v-col></v-row
                >
                <v-divider class="mt-1 mb-1"></v-divider>
                <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>

                <p class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
                <p v-if="item.PromoCode != null" class="mb-1">PromoCode: {{ item.PromoCode }}</p>
                <p v-if="item.PromoCode != null" class="mb-1">PromoCodeAmount: {{ item.PromoCodeAmount }}</p>
                <p class="mb-1">TotalAmount: {{ item.TotalAmount }}</p>
              </v-card>
            </td>
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="12" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>

                <p class="my-1">Name: {{ item.Name }}</p>
                <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                <p v-if="item.ScheduleTimeOrder > '2020-01-01 02:00:00.0000000'" class="mb-1">
                  Schedule Time Order: {{ filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}') }}
                </p>
                <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
                <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
                <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
                <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
                  Order Source Ref Number: {{ item.OrderSourceRefNumber }}
                </p>

                <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
                <p v-if="item.BranshAddress != null" class="mb-1">Bransh Address: {{ item.BranshAddress }}</p>
                <p v-if="item.AddressDetail != null" class="mb-1">Address Details: {{ item.AddressDetail }}</p>
                <p v-if="item.BuildingNo != null" class="mb-1">Building No: {{ item.BuildingNo }}</p>
                <p v-if="item.StreetNo != null" class="mb-1">Street Name: {{ item.StreetNo }}</p>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </app-card-actions>
    </v-col></v-row
  >
</template>

<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import DrawerPrint from '@/components/vc-print/DrawerPrint.vue'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { NotifyAsync } from '@core/api/Chat.js'
import { parseTime } from '@core/utils/filter'
import { mdiCalendarRange, mdiClockOutline, mdiPrinter } from '@mdi/js'
import useOrderList from './useOrderList'


// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { VcChangeStatus, AppCardActions, DrawerPrint },
  setup() {
      const PushNotification = (BrId) => {
        NotifyAsync({BranchId : BrId})
      }
    const {
      fetchOrders,
      OrderListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalOrderListTable,
      loading,
      expanded,
      searchDateFrom,
      searchDateTo,
      orderSourceFilter,
      OrderTotalLocal,
      resolveTotalIcon,
    } = useOrderList()

    return {
      PushNotification,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalOrderListTable,
      OrderListTable,
      loading,
      expanded,
      fetchOrders,
      searchDateFrom,
      searchDateTo,
      orderSourceFilter,
      OrderTotalLocal,
      resolveTotalIcon,
      filter: { parseTime },
      icons: {
        mdiPrinter,
        mdiClockOutline,
        mdiCalendarRange,
      },
    }
  },
}
</script>
