import { GetByUserId } from '@core/api/Order.js'
import { mdiCart, mdiCurrencyUsd } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useOrderList() {
  // Table Handlers
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'Customer Name', value: 'Name' },
    { text: 'Phone', value: 'PhoneNumber'},
    { text: 'Creation Time', value: 'CreationTime' },
    { text: 'Amount', value: 'TotalAmount' },
    { text: 'Status', value: 'Status', sortable: true },
    { text: 'Action', value: 'Action', sortable: false },
  ]

  const OrderListTable = ref([])
  const OrderListTablef = ref([])
  const expanded = ref([])
  const searchQuery = ref('')
  const searchDateFrom = ref(new Date())
  const searchDateTo = ref(new Date())
  const statusFilter = ref(null)
  const orderSourceFilter = ref(null)
  const totalOrderListTable = ref(0)
  const totalOrderListTablef = ref(0)
  const OrderTotalLocal = ref([])

  searchDateFrom.value.setHours(0, 0, 0, 0)
  searchDateTo.value.setHours(23, 59, 59, 999)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [true],
  })
  const loading = ref(false)

  const fetchOrders = () => {
    const UserId = JSON.parse(localStorage.getItem('userData')).Id
    console.log(UserId)
    GetByUserId({
      UserId: UserId,
      Any: searchQuery.value,
      OrderSource: orderSourceFilter.value,
    })
      .then(response => {
        response.Items.forEach((i, index) => {
          i.OrderTransations.forEach((item, index) => {
             if(item.Addon != ''){
              item.Addon = JSON.parse(item.Addon)
              item.Addon2 = item.Addon
             }
            })
        })
        // handle success
        OrderListTable.value = response.Items
        totalOrderListTable.value = response.length
        OrderTotalLocal.value = response.Totals
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, orderSourceFilter, options, searchDateFrom, searchDateTo], () => {
    loading.value = true
    fetchOrders()
  })
  const resolveTotalIcon = total => {
    if (total === 'Count') return { icon: mdiCart, color: 'primary' }
    if (total === 'Amount') return { icon: mdiCurrencyUsd, color: 'success' }
    return { icon: mdiCart, color: 'primary' }
  }
  return {
    fetchOrders,
    expanded,
    tableColumns,
    searchQuery,
    options,
    OrderListTable,
    OrderListTablef,
    statusFilter,
    totalOrderListTable,
    totalOrderListTablef,
    loading,
    searchDateFrom,
    searchDateTo,
    orderSourceFilter,
    OrderTotalLocal,
    resolveTotalIcon,
  }
}
